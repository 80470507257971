<script setup lang="ts">
const props = defineProps({
  hiddenTextWhenMobile: {
    type: Boolean,
    default: false,
  },

  path: {
    type: String,
    default: '',
  },

  isWhite: {
    type: Boolean,
    default: false,
  },

  noRedirect: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])

const router = useRouter()

function back() {
  if (props.noRedirect) {
    emit('click')

    return
  }

  if (props.path)
    navigateTo(props.path)
  else
    router.back()
}
</script>

<template>
  <button
    aria-label="Botão para voltar a página"
    class="flex items-center gap-2 font-semibold text-primary-400 my-4"
    :class="[
      { ['text-white']: isWhite },
    ]"
    type="button"
    @click="back"
  >
    <Icon name="mdi:arrow-left" class="w-5 h-5 md:w-5 md:h-5" />
    <span
      :class="props.hiddenTextWhenMobile ? 'hidden xl:inline' : 'inline'"
      class="text-xs font-semibold"
    >
      Voltar
    </span>
  </button>
</template>
